import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DoYouHaveAccount from 'pages/Learner/HaveAccount/DoYouHaveAccount';
import { navRoutes, courseCodeTypes } from 'constants';
import { Dropdown } from 'components/Inputs';
import { ConfirmationSuccessModal, Typography as T } from 'components';
import { useCreateLearner, useGetLearners } from 'api-calls/learners.queries';
import { BasicButton } from 'components/Button';
import { useSiteSettings } from 'context/site-settings';

const AlreadyEnrolled = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();
  const { courseCode } = useSiteSettings();
  const [selectedLearnerUniqueId, setSelectedLearnerUniqueId] = useState('');
  const [showSuccess, setShowSuccess] = useState({
    show: false,
    learnerUserId: '',
  });
  const { learners = [], isLoading: isLearnersLoading } = useGetLearners({
    providedCourseCode:
      courseCode === courseCodeTypes.TPD
        ? courseCodeTypes.CMID
        : courseCodeTypes.TPD,
  });
  const {
    mutateAsync: createLearner,
    error: createLearnerError,
    isLoading,
  } = useCreateLearner(null);

  const selectLearnerOptions = useMemo(() => {
    return learners
      .filter((l) => !l.deleted)
      .map((learner) => ({
        label: `${learner.fullName} (${learner.learnerUniqueId})`,
        value: learner.learnerUniqueId,
      }));
  }, [learners]);

  const handleAddLearnerToThisCourse = async () => {
    createLearner(
      {
        learnerUniqueId: selectedLearnerUniqueId,
        addToExistingAccount: true,
      },
      {
        onSuccess: (data) => {
          setShowSuccess({ show: true, learnerUserId: data.id });
        },
      }
    );
  };
  return (
    <>
      <ConfirmationSuccessModal
        variant="default"
        visible={showSuccess.show}
        setIsModalVisible={(status) => setShowSuccess(status)}
        title="Success"
        description="You have add this learner to Count Me In course"
        onConfirm={() => {
          navigate('/');
        }}
        confirmText="Return to Dashoboard"
        cancelText="View their profile"
        onCancel={() => {
          navigate(
            navRoutes.COMMON.LEARNER.replace(
              ':userId',
              showSuccess.learnerUserId
            )
          );
        }}
      />
      <DoYouHaveAccount
        insideSignup
        handleYes={() => {
          setSelectedOption('yes');
        }}
        handleNo={() => {
          navigate(`${navRoutes.COMMON.ADD_LEARNER}?createNewLearner=true`);
        }}
        selectedOption={selectedOption}
        insideLearnerReferral
      />
      {selectedOption === 'yes' && !isLearnersLoading && (
        <>
          <T.H1 mt="48px" mb="24px">
            Select learner
          </T.H1>
          <Dropdown
            label="Please select the learner from your organsiation"
            options={selectLearnerOptions}
            autocompleteProps={{
              getOptionLabel: (option) => {
                return option.label || '';
              },
              isOptionEqualToValue: (option, value) =>
                option.value === value.value,
            }}
            selected={selectLearnerOptions.find(
              (learner) => learner.value === selectedLearnerUniqueId
            )}
            handleChange={(value) => {
              setSelectedLearnerUniqueId(value);
            }}
          />

          <BasicButton
            mt="24px"
            variant="primary"
            disabled={!selectedLearnerUniqueId}
            loading={isLoading}
            handleClick={handleAddLearnerToThisCourse}
          >
            Select
          </BasicButton>
          {createLearnerError && (
            <T.P color="error" mt="8px">
              {createLearnerError.message}
            </T.P>
          )}
        </>
      )}
    </>
  );
};

export default AlreadyEnrolled;
