import React, { useEffect, useRef } from 'react';
import * as S from './style';

import * as utils from './utils';
import { useGeneralState } from 'context/general-state';
import CurrentExerciseButton from './CurrentExerciseButton';
import MilestoneSection from './MilestoneSection';
import { useAuth } from 'context/auth';

const scrollToRef = (ref) => {
  const container = document.querySelector('.scrollable-container');
  container.scrollTo({ top: ref.offsetTop - 220, behavior: 'smooth' });
};
const executeScroll = (ref) => scrollToRef(ref);

const ExerciseTimeline = ({
  milestones: _m,
  forPublic,
  milestoneIdToMoveTo,
}) => {
  const { state: generalState } = useGeneralState();
  const { user } = useAuth();

  const { activeExercise, milestones, lastCompletedMilestone } =
    utils.getActiveExWithFormattedMilestones({
      milestones: _m,
      lastCompletedExId: forPublic
        ? generalState?.lastCompletedExerciseId
        : undefined,
      milestoneIdToMoveTo,
    });

  const activeExerciseRef = useRef();
  const shouldScroll = useRef(true);

  useEffect(() => {
    if (activeExerciseRef.current && shouldScroll.current) {
      executeScroll(activeExerciseRef.current);
    }
  }, [activeExercise]);

  let counter = 0;
  // add a global index to each exercise and milestone progress card
  const milestonesWithIndex = milestones.map((milestone) => {
    const exercisesWithIndex = milestone.exercises.map((exercise) => {
      const exerciseWithIndex = { ...exercise, index: counter };
      counter++;
      return exerciseWithIndex;
    });

    const hidProgressCheck = user.isSelfSignedUp && !user.coachId;
    if (!hidProgressCheck) {
      counter++;
    }

    return {
      ...milestone,
      exercises: exercisesWithIndex,
      progressCheckCardIndex: counter,
    };
  });

  return (
    <S.Wrapper>
      <CurrentExerciseButton
        activeExerciseRef={activeExerciseRef}
        executeScroll={executeScroll}
      />

      {milestonesWithIndex.map((milestone, index) => {
        return (
          <MilestoneSection
            milestone={milestone}
            index={index}
            activeExercise={activeExercise}
            lastCompletedMilestone={lastCompletedMilestone}
            isLastMilestone={index === milestonesWithIndex.length - 1}
            activeExerciseRef={activeExerciseRef}
          />
        );
      })}
    </S.Wrapper>
  );
};

export default ExerciseTimeline;
